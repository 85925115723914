<template>
    <div>
        <form class="form-group row">
            <div class="col-md-12 mb-3">
                <span>연결할 설비 드라이버를 선택하여 설비유형을 생성합니다.</span>
            </div>
            <div class="col-md-12">
                <b-form-select v-model="selectedDriver" :options="options"></b-form-select>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    components: {},
    props: [ 'mode' ],
    created() { },
    mounted() { },
    data() {
        return {
            selectedDriver: '',
        };
    },
    computed: {
        isNewMode() {
            return this.mode === 'new';
        },
        driverList() {
            return this.$store.state.driverList;
        },
        options() {
            const options = this.driverList.map(driver => {
                return {
                    value: {
                        driverType: driver.driverType,
                        version: driver.version
                    },
                    text: driver.driverName
                };
            })

            options.unshift({ value: '', text: '드라이버 선택' });
            return options;
        },
    },
    watch: {},
    methods: {
        getDriver() {
            return this.selectedDriver;
        },
    },
}
</script>

<style scoped>

</style>
<template>
    <div class="container">
        <div class="row flex-grow-1">
                <div class="col">
                <!-- TableList -->
                <table-list-component ref="table-list" :title="''" :columns="columns" :rows="detailInfo.ctrlModes"
                    :keyField="'ctrlMode'" :transCodes="transCodes" :controls="controls" :useFilter="false" :mode="mode" :useMaxHeight="true"
                    :toggleFields="['enabled']" @button-click="handleListButtonClick" 
                    @row-click="handleListItemClick" @toggle-changed="handleItemToggle" />
        
                <!-- CtrlPropDetail Popup -->
                <b-modal ref="CtrlModeDetailModal" size="md" centered @hidden="closeCtrlModeModal">
                    <template #modal-header>
                        <span>제어 항목 추가</span>
                    </template>
                    <template #modal-footer centered>
                        <button v-show="isEditMode" type="button" class="btn btn-sm btn-primary" @click="handleAddListItem">{{ !tempCtrlMode ? '추가' : '저장' }}</button>
                        <button type="button" class="btn btn-sm btn-secondary" @click="closeCtrlModeModal">닫기</button>
                    </template>
                    <CtrlModeDetailPanel ref="CtrlModeDetailPanel" :mode="mode" :data="ctrlModeBuffer" :detailData="detailInfo" :modalTableList="modalCtrlModeList" :tempCtrlMode="tempCtrlMode" />
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import CtrlModeDetailPanel from "./CtrlModeDetailPanel.vue";

export default {
    components: {
        TableListComponent,
        CtrlModeDetailPanel
    },
    props: ['detailInfo', 'mode'],
    created() {
        this.setCtrlModeList();
    },
    mounted() {
    },
    data() {
        return {
            controls: [
                { event: 'delete', label: '-', class: 'btn-danger' },
                { event: 'regist', label: '+', class: 'btn-warning' },
            ],
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                }
            ],
            columns: [
                {
                    label: this.$t('제어 모드'),
                    field: 'ctrlMode',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('제어 모드명'),
                    field: 'ctrlModeName',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('제어항목'),
                    field: 'outProps',
                    type: 'text',
                },
                {
                    label: this.$t('사용 여부'),
                    field: 'enabled',
                    type: 'text',
                    width: '80px',
                }
            ],
            ctrlModeBuffer: null,
            tempCtrlMode: null,
            tempCtrlModeIndex: null,
            modalCtrlModeList: null,
        };
    },
    computed: {
        isEditMode() {
            return this.mode === 'edit';
        },
    },
    watch: {},
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;


            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }

        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if(toggle || check) return;
            that.tempCtrlMode = {...row};
            that.tempCtrlModeIndex = index;
            that.$refs.CtrlModeDetailModal.show();
        },     
        closeCtrlModeModal() {
            const that = this;
            if(that.tempCtrlMode) that.tempCtrlMode = null;
            that.$refs.CtrlModeDetailModal.hide();
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs['table-list'].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // await backEndApi.propCodes.deleteEquipType(item.propCode);
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.ctrlModes = that.detailInfo.ctrlModes.filter(ctrl => {
                            return !checked.find(checkedProp => checkedProp.ctrlMode === ctrl.ctrlMode);
                        })
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async registNew() {
            // this.$refs['list-detail'].setData(null);
            this.tempCtrlMode = null;
            this.$refs.CtrlModeDetailModal.show();
        },

        handleAddListItem() {
            
            const ctrlMode = this.$refs.CtrlModeDetailPanel.getData();

            if(this.tempCtrlMode) {
                this.$set(this.detailInfo.ctrlModes, this.tempCtrlModeIndex, this.tempCtrlMode);
            } else {
                if (ctrlMode) {
                    // Check Duplication (propCode, propName)
                    let check = this.detailInfo.ctrlModes.find(ctrl => ctrl.ctrlMode === ctrlMode.ctrlMode || ctrl.ctrlModeName === ctrlMode.ctrlModeName);
                    if (check) {
                        // TODO: Alert Message
                        console.error('TODO: Alert Message');
                        console.error(`In Property is already exists in ctrlModes. ctrlMode:${ctrlMode.ctrlMode}, ctrlModeName:${ctrlMode.ctrlModeName}`);
                    } else {
                        this.$set(this.detailInfo.ctrlModes, this.detailInfo.ctrlModes.length, ctrlMode);
                    }
                }
            }


            this.$refs.CtrlModeDetailModal.hide();
        },
        setCtrlModeList() {
            this.modalCtrlModeList = this.detailInfo.ioProps.filter(prop => ['Out', 'Both'].includes(prop.ioType))
        },
        handleItemToggle(key, field, status) {

            const that = this;
            const found = that.detailInfo.ctrlModes.find(item => item.ctrlMode === key)
            found.enabled = status
        },
    },
}
</script>

<style scoped>

.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}

</style>
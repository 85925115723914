<template>
    <div class="container">
        <div class="row flex-grow-1">
            <div class="col">
                <!-- TableList -->
                <TableListComponent ref="TableListComponent" :title="''" :columns="columns" :rows="detailInfo.calcProps"
                    :keyField="'propCode'" :transCodes="transCodes" :controls="controls" :mode="mode" :useMaxHeight="true"
                    :toggleFields="['enabled']" @button-click=" handleListButtonClick" :useFilter="false"
                    @row-click="handleListItemClick" @toggle-changed="handleItemToggle" />
     
                <!-- CalcPropDetail Popup -->
                <b-modal ref="CalcPropDetailModal" size="lg" centered @hidden="closeCalcPropModal">
                    <template #modal-header>
                        <h5>{{ tempCalcPropIndex !== null || undefined ? '계산 속성 편집' : '계산 속성 추가' }}</h5>
                    </template>
                    <template #modal-footer centered>
                        <button v-show="isEditMode" type="button" class="btn btn-lg btn-primary" @click="handleAddListItem">저장</button>
                        <button type="button" class="btn btn-lg btn-secondary" @click="closeCalcPropModal">닫기</button>
                    </template>
        
                    <CalcPropDetailPanel ref="CalcPropDetailPanel" :category="detailInfo.equipCategory" :mode="mode" :calcHintList="[...detailInfo.ioProps,...detailInfo.userProps,...detailInfo.calcProps]"/>
        
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import CalcPropDetailPanel from "./CalcPropDetailPanel.vue";

export default {
    components: {
        TableListComponent,
        CalcPropDetailPanel,
    },
    props: [ 'detailInfo', 'mode' ],
    created() {

        
    },
    mounted() {
    },
    data() {
        return {
            controls: [
                {event: 'delete', label: '-', class: 'btn-danger'},
                {event: 'regist', label: '+', class: 'btn-warning'},
            ],
            transCodes: [
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('속성 코드'),
                    field: 'propCode',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('속성 코드명'),
                    field: 'propName',
                    type: 'text',
                    tdClass: 'text-center font-weight-bold text-dark',
                },
                {
                    label: this.$t('단위 유형'),
                    field: 'unitType',
                    type: 'text',
                },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                },
                {
                    label: this.$t('사용 여부'),
                    field: 'enabled',
                    type: 'text',
                    width: '80px',
                }
            ],
            tempCalcPropIndex: null,
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },        
    },
    watch: {
        detailInfo() {
        },
    },
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;


            switch (event) {
                case 'regist':
                    await that.registNew();
                    break;

                case 'delete':
                    await that.deleteChecked();
                    break;

                default:
                    console.warn('Unhandled Component Event. event:', event, 'options:', options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if(toggle || check) return;
            that.tempCalcPropIndex = index;
            let findCalcProp = this.detailInfo.calcProps.find(item => item.propCode == row.propCode)
            await that.$refs.CalcPropDetailModal.show();
            await that.$refs.CalcPropDetailPanel.setData(findCalcProp);
        },
        closeCalcPropModal() {
            const that = this;
            if(this.tempCalcPropIndex !== null || undefined) this.tempCalcPropIndex = null;
            that.$refs.CalcPropDetailModal.hide()
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs['TableListComponent'].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // await backEndApi.propCodes.deleteEquipType(item.propCode);
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.calcProps = that.detailInfo.calcProps.filter(prop => {
                            return !checked.find(checkedProp => checkedProp.propCode === prop.propCode);
                        })
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti("목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async registNew() {
            const that = this;
            that.tempCalcProp = null;
            
            that.$refs.CalcPropDetailModal.show();
        },        
        handleAddListItem() {

            const calcProp = this.$refs.CalcPropDetailPanel.getData();

            if(calcProp) {
                if(this.tempCalcPropIndex !== null || undefined) {
                    this.$set(this.detailInfo.calcProps, this.tempCalcPropIndex, calcProp);
                    this.tempCalcPropIndex = null;
                } else {
                    let check = this.detailInfo.calcProps.find(prop => prop.propCode === calcProp.propCode || prop.propName === calcProp.propName);
                    if (check) {
                        // TODO: Alert Message
                        console.error('TODO: Alert Message');
                        console.error(`In Property is already exists in calcProps. propCode:${calcProp.propCode}, propName:${calcProp.propName}`);
                    } else {
                        this.$set(this.detailInfo.calcProps, this.detailInfo.calcProps.length, calcProp);
                    }
                }
            }

            this.$refs.CalcPropDetailModal.hide();
        },
        handleItemToggle(key, field, status) {

            const that = this;
            const found = that.detailInfo.calcProps.find(item => item.propCode === key)
            found.enabled = status
        },
    },
}
</script>

<style scoped>

.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}

</style>
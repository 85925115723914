<template>
    <div>
        <div class="row mt-3 p-5">
            <div class="form-group col-md-12">
                <div class="row">
                    <label class="col-md-3 col-form-label text-right" for="propCode">제어 모드 코드 :</label>
                    <input v-if="!tempCtrlMode" v-model="detailInfo.ctrlMode" type="text" class="form-control col-md-9" id="propCode" :disabled="!isEditMode">
                    <input v-else v-model="tempCtrlMode.ctrlMode" type="text" class="form-control col-md-9" id="propCode" :disabled="!isEditMode">
                </div>
            </div>
            <div class="form-group col-md-12">
                <div class="row">
                    <label class="col-md-3 col-form-label text-right" for="propName">제어 모드명 :</label>
                    <input v-if="!tempCtrlMode" v-model="detailInfo.ctrlModeName" type="text" class="form-control col-md-9" id="propName" :disabled="!isEditMode">
                    <input v-else v-model="tempCtrlMode.ctrlModeName" type="text" class="form-control col-md-9" id="propName" :disabled="!isEditMode">
                </div>
            </div>
            <div class="col-md-12">
                <p v-if="!tempCtrlMode" class="mb-0">제어모드에 포함시킬 제어 항목을 선택하세요.</p>
                <TableListComponent ref="TableListComponent" :mode="mode" :title="''" :columns="columns" :rows="tableList"
                    :keyField="'ctrlMode'" :transCodes="transCodes" :useFilter="false" :useMaxHeight="false" />
            </div>
        </div>
    </div>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue"

export default {
    components: {
        TableListComponent
    },
    props: ['data', "mode", "detailData", "tempCtrlMode", 'modalTableList'],
    data() {
        return {
            transCodes: [
                {
                    field: 'unitType',
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: 'unit',
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t('제어 항목'),
                    field: 'rowTitle',
                    type: 'text',
                    compositionFields: ['propName', 'propCode']
                },
                {
                    label: this.$t('관제 유형'),
                    field: 'ioType',
                    type: 'text',
                },
                {
                    label: this.$t('관제값 유형'),
                    field: 'unitType',
                    type: 'text',
                },
                {
                    label: this.$t('단위'),
                    field: 'unit',
                    type: 'text',
                }
            ],
            detailInfo: null,
            tableList: null,
        }
    },
    watch: {
        data() {
            this.detailInfo = this.data;
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
        
    },
    created() {
        this.detailInfo = this.initData();
        // if(this.tempCtrlMode) {
        //     let modalData = this.tempCtrlMode.outProps.map(prop => this.detailData.ioProps.find(dataProp => dataProp.propCode === prop.propCode));
        // }
        this.tableList = this.tempCtrlMode 
                        ? this.tempCtrlMode.outProps
                        .map(prop => this.detailData.ioProps.find(dataProp => dataProp.propCode === prop.propCode)) 
                        : this.modalTableList;
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
        initData() {
            return {
                ctrlMode : '',
                ctrlModeName: '',
                enabled: 'Y',
                outProps: null,
            }
        },
        setData(data) {
            if (data) {
                this.mode = 'view';
                this.detailInfo = data;
            } else {
                this.mode = 'edit';
                this.detailInfo = this.initData();
            }
        },
        getData() {
            this.setOutProps();
            return this.detailInfo;
        },
        selectionChanged(selected) {
            this.$emit("outModeAddSelectChange", selected.selectedRows);
        },
        setOutProps() {
            const that = this;

            that.detailInfo.outProps = this.$refs['TableListComponent'].getCheckedRows();

        },
    },
}
</script>